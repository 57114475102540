.title-header {
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin-bottom: 0.35em;
    color: #070942 !important;
    margin-top: -30px !important;
}

.in-frec{
    width:80px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #070942 !important;
    font-style: unset;
}

.css-1aquho2-MuiTabs-indicator {
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #070942 !important;
}

.css-1slnj5u-MuiPaper-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 10px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    padding: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    top:-7px !important;
}

.button-principal{
    background-color: #070942 !important;
    color: white !important;
}

.button-secondary{
    background-color: white !important;
    color: #070942 !important;
}

.button-blue{
    background-color: 00497E !important;
    color: white !important;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    font: inherit;
    letter-spacing: inherit;
    color: #070942 !important;
    padding: 4px 0 5px;
    border: 0 initial #070942 !important;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding-top: 1px;
    padding: 8.5px 14px;
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-width: 0;
    padding: 0;
    margin: 0;
    border-color: #070942 !important;
    vertical-align: top;
    width: 100%;
    color: #070942 !important;
}
.css-wb57ya-MuiFormControl-root-MuiTextField-root:focus {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-width: 0;
    padding: 0;
    margin: 0;
    border-color: #070942 !important;
    vertical-align: top;
    width: 100%;
    color: #070942 !important;
}
.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root{
    color: #070942 !important;
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #070942 !important;
}


.header-table {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: center;
    padding: 16px;
    color: #070942 !important;
    background-color: #EBECFF !important;
}

.body-table {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 16px;
    padding: 16px;
    color: #070942 !important;
    font-style: normal;
    font-weight: 400;
}


@media (min-width:600px){.css-znt7kn-MuiContainer-root{padding-left:24px;padding-right:24px;padding-top: 24px;}}

.css-cvumne-MuiDrawer-docked .MuiDrawer-paper{
    background-color: #EBECFF !important;
}

.div-new-update {
    background-color: rgb(235, 236, 255);
    font-size: 16px;
    color: rgb(7, 9, 66);
    border-radius: 0px 6px 0px 0px;
    min-height: 40px;
    width: 150px;
    left: 826px;
    top: 153px;
    float: right;
    text-align: center;
    margin-top: -25px;
    padding-top: 5px;
}

.div-correlativo {
    background-color: lightgrey;
    float: left;
    font-size: 16px;
    color: rgb(7, 9, 66);
    width: 210px;
    height: 40px;
    left: 616px;
    top: 153px;
    text-align: center;
    margin-top: -25px;
    padding-top: 5px;
}

.div-header-new {
    max-width: 360px;
    min-width: 360px;
    float: right;
}

.bg-base{
    background-color: #EBECFF !important;
}

.h6-space{
    width: 80px !important;
}

.css-label-red{
    color: red;
}

.css-label-green{
    color: green;
}

.css-label-blue{
    color: blue;
}